import React from 'react';
import Slides from '@ifeng-fe/ui_pc_slides';
import styles from './index.css';
import PropTypes from 'prop-types';
import { formatImage } from '@ifeng-fe/three_terminal/es/base/publicMethod/formatImage';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Slider extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content: slides } = this.props;
        const sliderTmpl = item => {
            return (
                <div className={styles.slideItem}>
                    <a data-nomask href={item.url} target="_blank" alt={item.title} title={item.title}>
                        <img src={item.thumbnail ? formatImage(item.thumbnail, 780, 440) : ''} />
                    </a>
                </div>
            );
        };

        const dotTmpl = item => {
            return (
                <div className={styles.dotItem}>
                    <div className={styles.innerText}>
                        <a href={item.url} target="_blank">
                            {item.title}
                        </a>
                    </div>
                </div>
            );
        };

        const config = {
            arrows: false,
            autoplay: true,
            dots: true,
            dotsAction: 'hover',
            direction: 'forward',
            pauseOnHover: true,
            autoplayInterval: 5000,
            axis: 'horizonta',
            initialSlide: 0,
            unitSize: 780,
            speed: 300,
            infinite: true,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.current,

            sliderTmpl,
            dotTmpl,
        };

        return (
            <div className={styles.slider}>
                <div className={styles.slideContaniner}>
                    <Slides content={slides} config={config} />
                </div>
            </div>
        );
    }
}

export default errorBoundary(Slider);
