import React from 'react';
import styles from './index.css';
import PropTypes from 'prop-types';
import { formatImage } from '@ifeng-fe/three_terminal/es/base/publicMethod/formatImage';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;

        return (
            <div className={styles.contentWrap}>
                {content.map(item => {
                    const { thumbnail, title, url, id } = item;

                    return (
                        <div className={styles.contentItem} key={id}>
                            <a href={url} target="_blank" className={styles.contentItemLink}>
                                <div className={styles.contentThumbnail}>
                                    {thumbnail ? (
                                        <img
                                            src={formatImage(thumbnail, 300, 169)}
                                            alt={title}
                                            className={styles.contentThumbnailImg}
                                        />
                                    ) : (
                                        <div className={styles.noThumbnail} />
                                    )}
                                </div>
                                <div className={styles.contentTitle}>{title}</div>
                            </a>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default errorBoundary(Content);
