import React from 'react';
import Style from '../index.css';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';

class ColList extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        // 获取组件用到的全部数据
        const { content } = this.props;
        // 获取数据key值并从小到大排序，key值格式为：col1, col2, col3 ...
        const sortedListKeys = Object.keys(content).sort((a, b) => {
            return parseInt(a.replace('col', '')) - parseInt(b.replace('col', ''));
        });
        // 获取分类导航jsx对象数组
        const colList = sortedListKeys.map((item, key) => {
            const col = content[item];

            return (
                <ul key={col.title + key} className={Style.col}>
                    <li className={Style.colTitle}>{col.title}</li>
                    {col.nav.map(item => {
                        const { title, url } = item;

                        return (
                            <li key={title}>
                                <a href={url} target="_blank" rel="noreferrer noopener">
                                    {title}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            );
        });

        return <div className={Style.colWrap}>{colList}</div>;
    }
}

export default errorBoundary(ColList);
