/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import styles from './index.css';
import LeftBox from './components/LeftBox';
import RightBox from './components/RightBox';
import SubNav from '../../components/SubNav';
import Chip from 'Chip';
import ToTop from '@ifeng-fe/container_channel/common/client/components/toTop';

class Layout extends PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;

        // 头部
        const headerData = {
            nav: content.nav,
            topAd: content.topAd,
            search: content.search,
            logo: content.logo,
            IE78: content.IE78,
        };

        // 主体部分左侧
        const leftData = {
            slideData: content.slideData,
            liveList: content.liveList,

            zixunTop: content.zixunTop,
            caijingTop: content.caijingTop,
            zhuantiTop: content.zhuantiTop,
            renwenTop: content.renwenTop,
            lishiTop: content.lishiTop,
            fangtanTop: content.fangtanTop,
            lianganTop: content.lianganTop,
            yuegangaoTop: content.yuegangaoTop,
            tebiejiemuTop: content.tebiejiemuTop,

            zixunContent: content.zixunContent,
            caijingContent: content.caijingContent,
            zhuantiContent: content.zhuantiContent,
            renwenContent: content.renwenContent,
            lishiContent: content.lishiContent,
            fangtanContent: content.fangtanContent,
            lianganContent: content.lianganContent,
            yuegangaoContent: content.yuegangaoContent,
            tebiejiemuContent: content.tebiejiemuContent,

            column: content.column,
        };

        const rightData = {
            jinpaijiemuTop: content.jinpaijiemuTop,
            jinpaijiemuContent: content.jinpaijiemuContent,
        };

        const footerData = {
            footerNav: content.footerNav,
            footerCopyright: content.footerCopyright,
        };

        return (
            <div>
                <div className={styles.headerWrap}>
                    <Header content={headerData} test />
                </div>
                <Chip id="230203" type="struct" title="凤凰卫视-首页子导航" groupName="正文" content={content.subNav}>
                    <SubNav windowMode="large" />
                </Chip>

                <div className={styles.main}>
                    <div className={styles.leftBox}>
                        <LeftBox content={leftData} />
                    </div>
                    <div className={styles.rightBox}>
                        <RightBox content={rightData} />
                    </div>
                </div>
                <div className={styles.footerWrap}>
                    <Footer content={footerData} />
                </div>
                <ToTop windowMode="large" />
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default errorBoundary(Layout);
