import React from 'react';
import styles from './index.css';
import PropTypes from 'prop-types';
import { formatImage } from '@ifeng-fe/three_terminal/es/base/publicMethod/formatImage';
import { asyncGetPlayNum } from '../../../../../../services/playNumUnit';
import errorBoundary from '@ifeng-fe/errorBoundary';

const formatTime = function formatTime(time) {
    if (time) {
        time = time.substring(0, 10);
    }

    return time;
};

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    state = {
        playNum: {},
    };

    // 获取播放量
    asyncGetPlayNum = async () => {
        const { content } = this.props;

        if (content && Array.isArray(content) && content.length > 0) {
            const playNum = await asyncGetPlayNum(this.props.content);

            this.setState({
                playNum,
            });
        }
    };

    componentDidMount() {
        this.asyncGetPlayNum();
    }

    render() {
        const { content } = this.props;

        return (
            <div className={styles.contentWrap}>
                {content.slice(0, 5).map(item => {
                    const { guid, thumbnail, title, url, newsTime } = item;

                    return (
                        <div className={styles.contentItem} key={guid}>
                            <a href={url} target="_blank" className={styles.contentItemLink}>
                                <div className={styles.contentThumbnail}>
                                    {thumbnail ? (
                                        <img
                                            src={formatImage(thumbnail, 194, 110)}
                                            alt={title}
                                            className={styles.contentThumbnailImg}
                                        />
                                    ) : (
                                        <div className={styles.noThumbnail} />
                                    )}
                                </div>
                                <div className={styles.contentTitle}>{title}</div>
                                <div className={styles.contentInfo}>
                                    <div className={styles.contentInfoNum}>
                                        <div className={styles.contentInfoNumIcon} />
                                        <div className={styles.contentInfoNumText}>{this.state.playNum[guid]}</div>
                                    </div>
                                    <div className={styles.contentInfoTime}>{formatTime(newsTime)}</div>
                                </div>
                            </a>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default errorBoundary(Content);
