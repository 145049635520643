import React, { Fragment } from 'react';
import styles from './index.css';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';

const hasSubPage = true;

const getScrollTop = el => {
    let top = el.offsetTop;
    let parent = el.offsetParent;

    while (parent != null) {
        top += parent.offsetTop;
        parent = parent.offsetParent;
    }

    return top;
};

const AnimateScrollY = (target, time) => {
    const timeStep = 1;
    const times = Math.ceil(time / timeStep);
    let start = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollStep = Math.ceil(Math.abs(target - start) / times);
    let count = 0;
    const timer = setInterval(() => {
        if (count < times) {
            start += scrollStep;
            window.scrollTo(0, start);
            count++;
        } else {
            window.scrollTo(0, target);
            clearInterval(timer);
        }
    }, timeStep);
};

class SubNav extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        windowMode: PropTypes.string,
        theme: PropTypes.string,
        active: PropTypes.string,
    };

    static defaultProps = {
        windowMode: 'normal',
        theme: '',
        active: '',
    };

    handleClick = title => {
        const el = document.querySelector(`[data-scroll-title="${title}"]`);

        if (el) {
            const targetTop = getScrollTop(el);

            AnimateScrollY(targetTop, 50);
        }
    };

    render() {
        const { content, windowMode, theme, active } = this.props;

        return (
            <div className={`${styles.nav} ${theme}`}>
                <div className={`${styles.navContainer} ${styles[windowMode]}`}>
                    {content.map(item => {
                        return (
                            <Fragment key={item.title}>
                                {hasSubPage ? (
                                    <div className={`${styles.navItem} ${item.param === active ? styles.active : ''}`}>
                                        <a href={item.url} target="_blank" className={styles.navItemLink}>
                                            <div className={styles.navItemContent}>{item.title}</div>
                                        </a>
                                    </div>
                                ) : (
                                    <div
                                        className={`${styles.navItem} ${styles.navItemScroll}`}
                                        onClick={() => {
                                            this.handleClick(item.title);
                                        }}>
                                        {item.title}
                                    </div>
                                )}
                            </Fragment>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default errorBoundary(SubNav);
