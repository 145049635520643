import React from 'react';
import Chip from 'Chip';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import Navigation from '@ifeng-fe/container_channel/common/client/components/newNav';
import UserInfo from '@ifeng-fe/ui_pc_userInfo';
import Search from '@ifeng-fe/ui_pc_search';
import styles from './index.css';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Logo from './logo/';

class Header extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        const logoData = {
            logo: content.logo,
        };
        const IE78 = content.IE78 || false;
        const SearchComp = errorBoundary(Search);
        const topNav = (
            <div className={styles.main_nav} key="topNav">
                <Chip id="20002" type="struct" title="导航" groupName="头部" content={content.nav}>
                    <Navigation />
                </Chip>
                <div className={styles.user}>
                    <NoSSR>
                        <UserInfo iconShow option={['register', 'login']} isIE78={IE78} />
                    </NoSSR>
                </div>
            </div>
        );

        // const topAd = (
        //     <div key="topAd" className={styles.ad}>
        //         <NoSSR onSSR={<div className={styles.box} />}>
        //             <Ad content={content.topAd} />
        //         </NoSSR>
        //     </div>
        // );

        const topMain = (
            <div className={styles.main} key="topMain">
                <div className={styles.logoWrap}>
                    <Chip id="200164" type="struct" title="Logo" groupName="头部" content={logoData}>
                        <Logo />
                    </Chip>
                </div>
                <div className={styles.searchWrap}>
                    <div className={styles.searchDirction}>
                        <Chip id="20005" type="struct" title="搜索" groupName="头部" content={content.search}>
                            <SearchComp expand IE78={IE78} defaultIsExpand />
                        </Chip>
                    </div>
                </div>
            </div>
        );

        // return [topNav, topAd, topSearch];
        return [topNav, topMain];
    }
}

export default errorBoundary(Header);
