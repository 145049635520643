import React from 'react';
import ReactDOM from 'react-dom';
import '../resetPC.css';
import Layout from './layout';
import { formatImage } from '@ifeng-fe/public_method';

/* eslint-disable */
const handleVideoSlideData = data => {
    try {
        return data.slice(0, 5).map(item => ({
            thumbnail: item?.thumbnail || item?.thumbnails?.image?.[0]?.url || item?.posterUrl || '',
            url: item.url,
            title: item.title,
        }));
    } catch (error) {
        console.error(error);
    }

    return data;
};

const handleSectionTopData = (data, id) => {
    const showMore = !!data.showMore;
    const subNav = data.subNav ? data.subNav : [];
    const title = data.title ? data.title : '';
    const urlMore = data.urlMore ? data.urlMore : '';

    return {
        showMore,
        subNav,
        title,
        urlMore,
        id,
    };
};

const handleSectionContentData = (data, id) => {
    const formatData =
        data &&
        data.map(item => {
            const title = item.title ? item.title : '';
            const thumbnail = item?.thumbnail || item?.thumbnails?.image?.[0]?.url || item?.posterUrl || '';
            const url = item.url ? item.url : '';
            const id = item.id ? item.id : '';
            const base62Id = item.base62Id ? item.base62Id : '';
            const guid = item.guid ? item.guid : '';
            const newsTime = item.newsTime ? item.newsTime : '';
            const dataNum = item?.dataNum ?? '';

            return { title, thumbnail, url, id, base62Id, guid, newsTime, dataNum };
        });

    return {
        list: formatData ? formatData : [],
        id,
    };
};

try {
    allData.slideData = allData.slideData && handleVideoSlideData(allData.slideData);

    allData.zixunTop = allData.zixunTop && handleSectionTopData(allData.zixunTop, '230206');
    allData.caijingTop = allData.caijingTop && handleSectionTopData(allData.caijingTop, '230207');
    allData.zhuantiTop = allData.zhuantiTop && handleSectionTopData(allData.zhuantiTop, '200166');
    allData.renwenTop = allData.renwenTop && handleSectionTopData(allData.renwenTop, '230208');
    allData.lishiTop = allData.lishiTop && handleSectionTopData(allData.lishiTop, '200167');
    allData.fangtanTop = allData.fangtanTop && handleSectionTopData(allData.fangtanTop, '200168');
    allData.lianganTop = allData.lianganTop && handleSectionTopData(allData.lianganTop, '230209');
    allData.yuegangaoTop = allData.yuegangaoTop && handleSectionTopData(allData.yuegangaoTop, '170194');
    allData.tebiejiemuTop = allData.tebiejiemuTop && handleSectionTopData(allData.tebiejiemuTop, '200169');

    allData.zixunContent = allData.zixunContent && handleSectionContentData(allData.zixunContent, '195948');
    allData.caijingContent = allData.caijingContent && handleSectionContentData(allData.caijingContent, '195949');
    allData.zhuantiContent = allData.zhuantiContent && handleSectionContentData(allData.zhuantiContent, '195950');
    allData.renwenContent = allData.renwenContent && handleSectionContentData(allData.renwenContent, '225880');
    allData.lishiContent = allData.lishiContent && handleSectionContentData(allData.lishiContent, '195951');
    allData.fangtanContent = allData.fangtanContent && handleSectionContentData(allData.fangtanContent, '225881');
    allData.lianganContent = allData.lianganContent && handleSectionContentData(allData.lianganContent, '256035');
    allData.yuegangaoContent = allData.yuegangaoContent && handleSectionContentData(allData.yuegangaoContent, '225882');
    allData.tebiejiemuContent =
        allData.tebiejiemuContent && handleSectionContentData(allData.tebiejiemuContent, '225883');

    allData.jinpaijiemuTop = allData.jinpaijiemuTop && handleSectionTopData(allData.jinpaijiemuTop, '170195');
    allData.jinpaijiemuContent =
        allData.jinpaijiemuContent && handleSectionContentData(allData.jinpaijiemuContent, '225884');
} catch (e) {
    console.error(e);
}

ReactDOM.render(<Layout content={allData} />, document.getElementById('root'));
/* eslint-enable */
