import React from 'react';
import styles from './index.css';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Title extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        column: PropTypes.array,
    };

    render() {
        const { content, column } = this.props;
        const { title, showMore, urlMore, subNav } = content;

        return (
            <div className={styles.titleWrap} data-scroll-title={title}>
                <div className={styles.brand} />
                {showMore ? (
                    <a className={styles.title} href={urlMore} target="_blank">
                        {title}
                    </a>
                ) : (
                    <div className={styles.title}>{title}</div>
                )}
                <div className={styles.subNav}>
                    {subNav
                        .filter(item => item.showIndex && (item.onlyIndex || column.includes(item.wemediaEAccountId)))
                        .map((item, index, array) => {
                            const { title, wemediaEAccountId, onlyIndex, onlyIndexLink } = item;
                            const url = onlyIndex ? onlyIndexLink : `${urlMore}/${wemediaEAccountId}`;

                            return (
                                <div className={styles.subNavItem} key={`${wemediaEAccountId}_${title}`}>
                                    <a href={url} target="_blank">
                                        {title}
                                    </a>
                                    {index < array.length - 1 ? <span className={styles.subNavSeparate}>|</span> : null}
                                </div>
                            );
                        })}
                </div>
                {showMore ? (
                    <div className={styles.more}>
                        <a href={urlMore} target="_blank">
                            更多 &gt;
                        </a>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default errorBoundary(Title);
