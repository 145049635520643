import { jsonp, ajax } from '@ifeng-fe/ui_base';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;

/* eslint-enable */

if (typeof window !== 'undefined' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

/**
 * 获取卫视英文页文章
 * @param {String} id
 */

export const getTvArticle = async id => {
    try {
        const res = await jsonp(`${apiBaseUrl}/phtv/index/getArticleData/${id}/getTvArticle`, {
            jsonpCallback: 'getTvArticle',
            cache: false,
        });

        return res.data;
    } catch (e) {
        console.error(e);
    }
};

/**
 * 获取卫视英文页人员介绍结构化碎片数据
 * @param {String} id
 */

export const getTvStruct = async id => {
    try {
        const res = await jsonp(`${apiBaseUrl}/phtv/index/getStructData/${id}/getTvStruct`, {
            jsonpCallback: 'getTvStruct',
            cache: false,
        });

        return res.data;
    } catch (e) {
        console.error(e);
    }
};

/**
 * 获取卫视英文页联系方式
 * @param {String} id
 */

export const getTvStatic = async id => {
    try {
        const res = await jsonp(`${apiBaseUrl}/phtv/index/getStaticData/${id}/getTvStatic`, {
            jsonpCallback: 'getTvStatic',
            cache: false,
        });

        return res.data;
    } catch (e) {
        console.error(e);
    }
};

// 获取视频播放数
export const getVideoComment = async vid => {
    // const { schemaCheck } = await import('./validate');

    let apiUrl = '//survey.news.ifeng.com/api/getaccumulatorweight?';

    // 接口拼接
    if (vid.length > 0) {
        vid.forEach(item => {
            apiUrl += `key[]=${item}&`;
        });
    }

    let data = await jsonp(apiUrl, {
        data: {
            format: 'js',
            serviceid: 1,
        },
        jsonCallback: 'getVideoComment1',
        cache: false,
    });
    // const requestUrl = `//survey.news.ifeng.com/api/getaccumulatorweight?format=js&key=${vid}&callback=getaccumulator`;

    // data = schemaCheck(data.data, 'voteNumSchema', requestUrl);
    data = data.data;

    return data ? data : null;
};

export const getPhtvperiodlist = async (from, to) => {
    const apiUrl = 'https://nine.ifeng.com/phtvperiodlist';

    let data = await jsonp(apiUrl, {
        data: {
            from,
            to,
        },
        jsonCallback: 'menuCallback',
        cache: false,
    });

    data = data.data;

    return data ? data : null;
};

export const getChannelList = async (kvid, pageNum) => {
    const apiUrl = `${apiBaseUrl}/phtv/channel/getChannelLIst/${kvid}/${pageNum}`;

    try {
        const res = ajax(apiUrl);

        return res;
    } catch (e) {
        console.error(e);
    }
};

export const getPresenterList = async pageNum => {
    const apiUrl = `${apiBaseUrl}/phtv/presenter/${pageNum}`;

    try {
        const res = ajax(apiUrl);

        return res;
    } catch (e) {
        console.error(e);
    }
};

export const getReporterLIst = async pageNum => {
    const apiUrl = `${apiBaseUrl}/phtv/reporter/${pageNum}`;

    try {
        const res = ajax(apiUrl);

        return res;
    } catch (e) {
        console.error(e);
    }
};

export const getCommentatorLIst = async pageNum => {
    const apiUrl = `${apiBaseUrl}/phtv/commentator/${pageNum}`;

    try {
        const res = ajax(apiUrl);

        return res;
    } catch (e) {
        console.error(e);
    }
};

export const getManagementLIst = async pageNum => {
    const apiUrl = `${apiBaseUrl}/phtv/management/${pageNum}`;

    try {
        const res = ajax(apiUrl);

        return res;
    } catch (e) {
        console.error(e);
    }
};
