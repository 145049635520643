import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Chip from 'Chip';
import ColList from './ColList';
// import FooterCopyright from '@ifeng-fe/container_channel/common/client/components/footer';
import FooterCopyright from './FooterCopyright';
import styles from './index.css';

class Footer extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;

        return (
            <div className={styles.footer}>
                {/* <Chip id="200163" type="struct" title="凤凰卫视-底部导航" groupName="底部" content={content.footerNav}>
                    <ColList />
                </Chip> */}
                <Chip id="20036" type="struct" title="底部公用版权" groupName="底部" content={content.footerNav}>
                    <div key="footer" className={styles.footerBottom}>
                        <FooterCopyright content={content.footerCopyright} theme={styles.footerCopyright} />
                    </div>
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(Footer);
