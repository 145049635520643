import React from 'react';
import styles from './index.css';
import iconTV from '../../../images/i_tv.png';
import iconLive from '../../../images/i_live.gif';
import errorBoundary from '@ifeng-fe/errorBoundary';
import PropTypes from 'prop-types';

// TODO: 提取到constants中
const PHTV_CN = 'phtvChinese';
const PHTV_HK = 'phtvHK';
const PHTV_NEWS = 'phtvNews';
const DEFAULT_CHANNEL = PHTV_NEWS;
const PHTV_LINKS = {
    [PHTV_CN]: 'https://phtv.ifeng.com/live?channel=phtvChinese',
    [PHTV_HK]: 'https://phtv.ifeng.com/live?channel=phtvHK',
    [PHTV_NEWS]: 'https://phtv.ifeng.com/live?channel=phtvNews',
};

class LiveChannel extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        const { channel, phtvNews, phtvChinese, phtvHK } = content;

        phtvNews.url = PHTV_LINKS[PHTV_NEWS];
        phtvChinese.url = PHTV_LINKS[PHTV_CN];
        phtvHK.url = PHTV_LINKS[PHTV_HK];

        return (
            <div className={styles.wrap}>
                <div className={styles.live}>
                    <a href={PHTV_LINKS[DEFAULT_CHANNEL]} target="_blank" className={styles.liveLink}>
                        <div className={styles.liveWrap}>
                            <div className={styles.liveIcon}>
                                <img src={iconTV} alt="" />
                            </div>
                            <div className={styles.liveTitle}>{channel.title}</div>
                        </div>
                    </a>
                </div>
                {[phtvNews, phtvChinese].map(item => (
                    <div className={styles.subLive} key={item.url}>
                        <a href={item.url} target="_blank" className={styles.subLiveLink}>
                            <div className={styles.subLiveWrap}>
                                <div className={styles.subLiveLeft}>
                                    <div className={styles.subLiveCircle} />
                                    <img src={item.thumbnail} alt="" className={styles.subLiveLeftBg} />
                                    <img src={iconLive} alt="" className={styles.subLiveLeftFg} />
                                </div>
                                <div className={styles.subLiveRight}>
                                    <div className={styles.subLiveTitle}>{item.title}</div>
                                    <div className={styles.subLiveDesc}>{item.desc}</div>
                                </div>
                            </div>
                        </a>
                    </div>
                ))}
            </div>
        );
    }
}

export default errorBoundary(LiveChannel);
