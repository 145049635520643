import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * 定义 Logo 组件
 */
class Logo extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { logo },
        } = this.props;

        return (
            <a href={logo.url} target="_blank">
                <img src={logo.src} alt={logo.title} width={logo.width} height={logo.height} />
            </a>
        );
    }
}

export default errorBoundary(Logo);
