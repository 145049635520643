import React from 'react';
import styles from './index.css';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Title extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;

        return (
            <div className={styles.titleWrap}>
                <div className={styles.brand} />
                <div className={styles.title}>{content.title}</div>
            </div>
        );
    }
}

export default errorBoundary(Title);
