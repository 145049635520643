import React from 'react';
import Title from './Title';
import Content from './Content';
import Chip from 'Chip';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';

class RightBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        const { jinpaijiemuTop, jinpaijiemuContent } = content;

        return (
            <div>
                <Chip
                    id={jinpaijiemuTop.id}
                    type="struct"
                    title="凤凰卫视-首页右侧标题-金牌节目专区"
                    groupName="正文-金牌节目专区"
                    content={jinpaijiemuTop}>
                    <Title />
                </Chip>
                <Chip
                    id={jinpaijiemuContent.id}
                    type="recommend"
                    title="凤凰卫视-首页右侧-金牌节目专区"
                    groupName="正文-金牌节目专区"
                    content={jinpaijiemuContent.list}>
                    <Content />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(RightBox);
