import React from 'react';
import Slider from './Slider';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import Section from './Section';
import LiveChannel from './LiveChannel';
import errorBoundary from '@ifeng-fe/errorBoundary';

class LeftBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    render() {
        const { content } = this.props;
        const column = content.column.map(item => item.wemediaEAccountId);

        return (
            <div>
                <Chip
                    id="288072"
                    type="recommend"
                    title="凤凰卫视首页-焦点图强插第三位"
                    content={content.slideData}>
                    <Slider />
                </Chip>
                {/* <Chip
                    id="170196"
                    type="struct"
                    title="凤凰卫视首页-直播频道列表"
                    groupName="正文"
                    content={content.liveList}>
                    <LiveChannel />
                </Chip> */}
                <div style={{ height: '2.857143rem' }} />

                <Section
                    top={content.zixunTop}
                    topid={content.zixunTop.id}
                    topTitle={'凤凰卫视-首页分类标题-资讯'}
                    content={content.zixunContent.list}
                    contentid={content.zixunContent.id}
                    contentTitle={'凤凰卫视-首页分类-资讯'}
                    groupName={'正文-资讯'}
                    column={column}
                />
                <Section
                    top={content.caijingTop}
                    topid={content.caijingTop.id}
                    topTitle={'凤凰卫视-首页分类标题-财经'}
                    content={content.caijingContent.list}
                    contentid={content.caijingContent.id}
                    contentTitle={'凤凰卫视-首页分类-财经'}
                    groupName={'正文-财经'}
                    column={column}
                />
                <Section
                    top={content.zhuantiTop}
                    topid={content.zhuantiTop.id}
                    topTitle={'凤凰卫视-首页分类标题-专题'}
                    content={content.zhuantiContent.list}
                    contentid={content.zhuantiContent.id}
                    contentTitle={'凤凰卫视-首页分类-专题'}
                    groupName={'正文-专题'}
                    column={column}
                />
                <Section
                    top={content.renwenTop}
                    topid={content.renwenTop.id}
                    topTitle={'凤凰卫视-首页分类标题-人文'}
                    content={content.renwenContent.list}
                    contentid={content.renwenContent.id}
                    contentTitle={'凤凰卫视-首页分类-人文'}
                    groupName={'正文-人文'}
                    column={column}
                />
                <Section
                    top={content.lishiTop}
                    topid={content.lishiTop.id}
                    topTitle={'凤凰卫视-首页分类标题-历史'}
                    content={content.lishiContent.list}
                    contentid={content.lishiContent.id}
                    contentTitle={'凤凰卫视-首页分类-历史'}
                    groupName={'正文-历史'}
                    column={column}
                />
                <Section
                    top={content.fangtanTop}
                    topid={content.fangtanTop.id}
                    topTitle={'凤凰卫视-首页分类标题-访谈'}
                    content={content.fangtanContent.list}
                    contentid={content.fangtanContent.id}
                    contentTitle={'凤凰卫视-首页分类-访谈'}
                    groupName={'正文-访谈'}
                    column={column}
                />
                <Section
                    top={content.lianganTop}
                    topid={content.lianganTop.id}
                    topTitle={'凤凰卫视-首页分类标题-两岸'}
                    content={content.lianganContent.list}
                    contentid={content.lianganContent.id}
                    contentTitle={'凤凰卫视-首页分类-两岸'}
                    groupName={'正文-两岸'}
                    column={column}
                />
                <Section
                    top={content.yuegangaoTop}
                    topid={content.yuegangaoTop.id}
                    topTitle={'凤凰卫视-首页分类标题-粤港澳'}
                    content={content.yuegangaoContent.list}
                    contentid={content.yuegangaoContent.id}
                    contentTitle={'凤凰卫视-首页分类-粤港澳'}
                    groupName={'正文-粤港澳'}
                    column={column}
                />
                <Section
                    top={content.tebiejiemuTop}
                    topid={content.tebiejiemuTop.id}
                    topTitle={'凤凰卫视-首页分类标题-特别节目'}
                    content={content.tebiejiemuContent.list}
                    contentid={content.tebiejiemuContent.id}
                    contentTitle={'凤凰卫视-首页分类-特别节目'}
                    groupName={'正文-特别节目'}
                    column={column}
                />
            </div>
        );
    }
}

export default errorBoundary(LeftBox);
