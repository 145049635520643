import React from 'react';
import Title from './Title';
import Chip from 'Chip';
import PropTypes from 'prop-types';
import styles from './index.css';
import Content from './Content';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Section extends React.PureComponent {
    static propTypes = {
        top: PropTypes.object,
        topid: PropTypes.string,
        topTitle: PropTypes.string,
        content: PropTypes.array,
        contentid: PropTypes.string,
        contentTitle: PropTypes.string,
        groupName: PropTypes.string,
        column: PropTypes.array,
    };

    render() {
        const { top, topid, topTitle, content, contentid, contentTitle, groupName, column } = this.props;

        return (
            <div className={styles.section}>
                <Chip id={topid} type="struct" title={topTitle} groupName={groupName} content={top}>
                    <Title column={column} />
                </Chip>
                {/* <Chip id={contentid} type="recommend" title={contentTitle} groupName={groupName} content={content}> */}
                <Content content={content} />
                {/* </Chip> */}
            </div>
        );
    }
}

export default errorBoundary(Section);
